


import { useEffect, useState } from "react"
import axios from "axios"
import {  Modal,Form, InputGroup, Button, FormControl } from 'react-bootstrap';

function AlertMessage({show}){


    return (

        <Modal show={show} style={{borderRadius: "2opx !important"}}>
        <Modal.Header closeButton={false}>
          <Modal.Title>
            <img src="warning-sign-9749.png" width={"100px"}/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> <strong style={{"font-size": "20px"}}>
        To apply the latest change, please restart the device.
        </strong>
        </Modal.Body>

        </Modal>
    )
}

export default  AlertMessage