
import {useState, useEffect}  from 'react'
import './App.css';
import axios from 'axios'
import LoadingAlert from './LoadingAlert'
import Wave from 'react-wavify';
import config from './config.json'

import { Container, Row, Col, Modal,Form, InputGroup, Button, FormControl, Alert } from 'react-bootstrap';

const devices_info=config
function App() {
    const apiKey = process.env.REACT_APP_PSW_INT  


    const [level_pu, SetLevel]= useState([]);
    const [label, setLabel]= useState("OPEN")
    const [showLoading, setShowLoading]= useState(false);
    const [admin, setAdmin]=useState(false)
    const [isOver, setover]=useState(false)
    const [indexIsland, setIndexIsland]=useState(undefined)
    const [indexDevice, setindexDevice]=useState(undefined)    
    let send=true;
    const params = new URLSearchParams(document.location.search);

    function mappingValue(x,  in_min,  in_max,  out_min,  out_max) {
          return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
    }
   
    
    function trovaIsolaConId( idCercato) {
      for (let i=0; i<devices_info.isole.length; i++){
        for(let j=0; j<devices_info.isole[i].devices.length; j++){
          if (devices_info.isole[i].devices[j].id === idCercato) {
            return [i, j]
            
        }
        }
      }
  }
  
      useEffect(()=>{
       
          try{
            let single_device= params.get("id");
            let devices=[]
            let indexSingle
            let info= []
            if(single_device!=null){
              let info= trovaIsolaConId(single_device)
              console.log(info)
              if(info!=null){
                setIndexIsland(info[0])
                indexSingle=info[1]
                setindexDevice(info[1])
                devices=devices_info.isole[info[0]]
              }
            }
            else{
              devices= devices_info.isole[params.get("island")]
              setIndexIsland(params.get("island"))
              if(params.get("id")!=undefined){
                setindexDevice(params.get("id"))
              }
            }

            let keys_redis=[]
        
            
              keys_redis.push(single_device+"_level")
              keys_redis.push(single_device+"_zerolevel")
              keys_redis.push(single_device+"_counter")
           
            console.log(keys_redis)
            axios.post("https://fleet85.smartme.io:40148/api/v1/system/datastore/query", 
            {"action": "get", "args": {"key": keys_redis, "type": "appl"}},
            {
              headers:{
                "Authorization": apiKey
              }
            }).then(e=>{
              SetLevel(e.data.arancino.datastore.result)
              
              try{
                let value=getLevel(info[1])
                console.log("indexDevice", indexDevice)
                console.log("level", value)
                if(!isNaN(value))
                  
                  if(value<0){
                    value=0.0
                  }
                  else if(value>=100){
                    value=100;
                    axios.post("https://fleet85.smartme.io:40148/api/v1/system/datastore/query", 
                      {"action": "pub",
                      "args": {
                          "items": [{
                              "channel": params.get("id")+"_full",
                              "message": "1"
                          }
                          ]
                      }
                      },
                      {
                        headers:{
                          "Authorization": apiKey
                        }
                      })
                  }
                  
                 
              }catch(e){
                
              }
             
            }).catch(e=>{
              
            })
          }catch(e){
            console.error(e)
          }
    }, [])


    function getLevel(index){
      
      try{
        let tmp= mappingValue(level_pu[0], level_pu[1],30.0, 0.0,100.0).toFixed(0)
        console.log(tmp, index)
        console.log(level_pu[0], level_pu[1])
        if(tmp>100){
          if(send){
            axios.post("https://fleet85.smartme.io:40148/api/v1/system/datastore/query", 
              {"action": "pub",
              "args": {
                  "items": [{
                      "channel": params.get("id")+"_full",
                      "message": "1"
                  }
                  ]
              }
              },
              {
                headers:{
                  "Authorization": apiKey
                }
              })
            send=false
          
          }
          return 100
          
        }
        else if(tmp<0){
          return 0
        }
        else if(isNaN(tmp)){
          return 0
        }
        else{
          return Math.round(tmp/10)*10
        }
      }
      catch(e){
        return 0
      }

    }

    useEffect(()=>{

      
        if(isOver){
          console.log("over 100%")
        
          let msg=`{
            "message":"BIN FULL",
            "type": "alert",
            "source": "${params.get("id")}",
            "source_label": "Poopick 00"
            }`
            
              console.log("is over and not admin")
              axios.post("https://fleet85.smartme.io:40148/api/v1/system/datastore/query", 
              {"action": "pub",
              "args": {
                  "items": [{
                      "channel": params.get("id")+"_full",
                      "message": "1"
                  }
                  ]
              }
              },
              {
                headers:{
                  "Authorization": apiKey
                }
              })
            

          // mqttPublish({topic:"poopick_events/", qos:0,payload:msg })
        }
        // let msg=`{
        //   "message":"BIN FULL",
        //   "type": "alert",
        //   "source": "${params.get("id").split("/")[0]}",
        //   "source_label": "Poopick 00"
        //   }`
        // mqttPublish({topic:"poopick_events/", qos:0,payload:msg })
        // console.log("send message")
      
    }, [ isOver])






    function Door(island, index){
      let devices= devices_info.isole[island]
      let item=devices.devices[index]
      const params = new URLSearchParams(document.location.search);
      if(level_pu[0]!=undefined){
        axios.post("https://fleet85.smartme.io:40148/api/v1/system/datastore/query", 
        {"action": "pub",
        "args": {
            "items": [{
                "channel": item.id+"_prc",
                "message": "1"
            }
            ]
        }
      },
         {
          headers:{
            "Authorization": apiKey
          }
        }).then(e=>{
          
        }).catch(e=>{
         
        })
        setShowLoading(true)

        setTimeout(()=>{
          setShowLoading(false)
        },3000)
      }

    
      // arancino/cortex/87654321/

     



    }






  return (
    <div className="App">
      <header >
        <img width={"100px"}src="poopick.png"></img>
        <h3>Environment and Waste Management</h3>
      </header>
    
      <body >
      {  indexIsland==undefined || indexDevice==undefined ? <> <p>No device selected or Not Found</p></>:
              <Container>
                  <Row>
                    <Col>
                      <div>
                        <div class="bodyApp">
                          <div class="Ocean">
                            <Wave fill={(getLevel(indexDevice)>=100? "rgb(128,0,0)" :"forestgreen")}
                                  paused={false}
                            
                                  style={{ display: 'flex', fill:"forestgreen" }}
                                  options={{
                                    height: 100,
                                    amplitude: 10,
                                    speed: 0.25,
                                    points: 5,
                                    fill: "forestgreen"
                                  }}
                            />
                            <div class={(getLevel(indexDevice)>=100 ? "Level100": "Level")} style={{height:getLevel(indexDevice)+"%"}} ></div>
                          </div>
                        </div>
                        <p class="precent">{getLevel(indexDevice)}%</p>
                        <div class="ButtonDiv">
                          <button disabled={getLevel(indexDevice)>=100 } class="Button" onClick={e=>{Door(indexIsland,indexDevice)}}>
                            <div className='BtnDiv'>
                              <img width={"30px"} src='/power.png'/>{label}
                            </div>
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container> 
}
      </body>
      <LoadingAlert show={showLoading}/>

    </div>
   
  );
}

export default App;
