


import { useEffect, useState } from "react"
import axios from "axios"
import {  Modal,Form, InputGroup, Button, FormControl } from 'react-bootstrap';

function ConfirmModal({show, openPSW, handleClose}){


    return (

        <Modal show={show} >
        <Modal.Header closeButton={false}>
          <Modal.Title>Calibration</Modal.Title>
        </Modal.Header>
        <Modal.Body> <strong>Are you sure you want to discard the previous calibration and create a new one?</strong>
        </Modal.Body>
        <Modal.Footer style={{justifyContent:"space-between"}}>
            <Button variant="danger" style={{backgroundColor:"rgb(128,0,0)", justifyContent:"space-between"}} onClick={handleClose}>Cancel</Button>
            <Button variant="success" onClick={openPSW}>Yes</Button>
    
            </Modal.Footer>
        </Modal>
    )
}

export default  ConfirmModal